import { useRef, useEffect } from 'react';
import { invokeSessionApi } from '../services/usage-tracker';
import { useTracking } from 'react-tracking';

const useIdleDetection = (timeout: number) => {
    const isIdle = useRef(false);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const { trackEvent, getTrackingData } = useTracking<any>({ f: 'IDLE_DETECTION_HOOK', at: new Date() });

    const resetTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        isIdle.current = false;
        timer.current = setTimeout(() => {
            isIdle.current = true;
            invokeSessionApi();
        }, timeout);
    };

    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        trackEvent({ ...getTrackingData(), e: 'SESSION_ENDED', at: new Date() });
        invokeSessionApi();
        localStorage.removeItem('session');
    };

    useEffect(() => {
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('beforeunload', beforeUnloadHandler);
        resetTimer();
        return () => {
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('beforeunload', beforeUnloadHandler);
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [timeout]);
    return isIdle.current;
};

export default useIdleDetection;
