import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ScenarioBuilder from 'pages/scenarioPlanner/ScenarioBuilder/Filters';
// Lazy-loaded components

const ScenarioPlanner = Loadable(lazy(() => import('pages/scenarioPlanner/index')));

// UK Routes
export function getUKRoutes() {
    return [
        {
            path: '/',
            element: <AuthGuard><MainLayout /></AuthGuard>,
            children: [
                { index: true, element: <ScenarioPlanner /> },
                { path: 'scenarioPlanner', element: <ScenarioPlanner /> },
                { path: 'scenarioPlanner/scenarioBuilder', element: <ScenarioBuilder /> }
            ]
        }
    ];
}
