import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { dotPulse } from 'ldrs';

interface SingleSelectDropdownProps {
  label: string;
  options: any[];
  selected: any;
  onChange: (value: any) => void;
  isLoading: Boolean;
}

const SingleSelectDropdown = ({
  label,
  options,
  selected,
  onChange,
  isLoading
}: SingleSelectDropdownProps) => {
  dotPulse.register();
  return (
    <TextField
      sx={{ border: 1, borderColor: "#9e9e9e", borderRadius: '5px' }}
      select
      label={label}
      value={selected || ""}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      size="small"
      InputProps={{
        startAdornment: isLoading ? (<InputAdornment position="start">
          <l-dot-pulse
            size="16"
            speed="0.5"
            color="gray"
          ></l-dot-pulse>
        </InputAdornment>) : null,
        style: { fontSize: "0.75rem" },
      }}
      InputLabelProps={{
        shrink: !!selected,
        style: {
          paddingTop: 1.5,
          fontSize: isLoading ? "0rem" : "0.75rem",
        },
      }}
    >
      {options?.map((option, index) => (
        <MenuItem key={index} value={option} sx={{ fontSize: "0.75rem" }}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SingleSelectDropdown;
