import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AdminPage = Loadable(lazy(() => import('pages/admin')));

//---------Defining privilage routes------------//
const adminRoute = [{
  path: '/adminPage',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/adminPage',
      element: <AdminPage />
    }
  ]
}]

export function getPrivilegedRoutes() {
  const user = localStorage.getItem('user');
  if (user) {
    const userAccessDetails = JSON.parse(user || "");
    const adminRoles = ['KCA', 'Dev_UI', 'Dev_Admin'];

    // Check if any of the roles in the array match the admin roles
    if (userAccessDetails.myrole.some((role: string) => adminRoles.includes(role))) {
      return adminRoute;
    }

    // Additional cases for other roles can be added here if needed
    switch (userAccessDetails.myrole[0]) {
      case 'FIN':
        break;
      case 'SAL':
        break;
      case 'MKT':
        break;
      default:
        break;
    }
    return [];
  } else {
    return [];
  }
}

