export const productSearchConfig = {
  columns: [
    { key: "mfr", label: "Manufacturer" },
    { key: "cust", label: "Customer" },
    { key: "cat", label: "Category" },
    { key: "brd", label: "Brand" },
    { key: "subBrd", label: "Sub Brand" },
    { key: "ppg", label: "PPG" },
  ],
  groups: [
    { name: "Group 1", keys: ["mfr", "cust", "cat"], type: "single" as "single" },
    { name: "Group 2", keys: ["brd", "subBrd", "ppg"], type: "multi" as "multi" },
  ],
};

export const order = ["mfr", "cust", "cat", "brd", "subBrd", "ppg"];

export const getProductSearchConfig = (elasticityLevel: string) => {
  let columns = [
    { key: "mfr", label: "Manufacturer" },
    { key: "cust", label: "Customer" },
    { key: "cat", label: "Category" },
    { key: "brd", label: "Brand" },
    { key: "subBrd", label: "Sub Brand" },
    { key: "ppg", label: "PPG" },
  ];

  if (elasticityLevel === "Brand") {
    columns = columns.filter(
      (column) => column.key !== "subBrd" && column.key !== "ppg"
    );
  } else if (elasticityLevel === "Sub Brand") {
    columns = columns.filter((column) => column.key !== "ppg");
  }

  return {
    columns,
    groups: [
      { name: "Group 1", keys: ["mfr", "cust", "cat"], type: "single" as "single" },
      {
        name: "Group 2",
        keys: columns
          .filter(
            (column) =>
              column.key === "brd" || column.key === "subBrd" || column.key === "ppg"
          )
          .map((column) => column.key),
        type: "multi" as "multi",
      },
    ],
  };
};