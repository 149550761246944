import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMainRoutes } from 'routes/MainRoutes';

// types
import { MenuProps } from 'types/menu';

// initial state
const initialState: MenuProps = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  selectedID: null,
  drawerOpen: false,
  componentDrawerOpen: true,
  menu: {},
  error: null
};

let allMenuItems = [{
  id: 'dashboard',
  title: 'dashboard',
  type: 'item',
  icon: 'dashboard',
  url: '/'
},
{
  id: 'priceelasticity',
  title: 'Price Elasticity Tab',
  type: 'item',
  url: '/priceelasticity',
  icon: 'elasticity'
},
{
  id: 'priceelasticitySimulator',
  title: 'Price Elasticity Simulator',
  type: 'item',
  url: '/priceelasticitysimulator',
  icon: 'simulator'
},
{
  id: 'adminpage',
  title: 'Admin Page',
  type: 'item',
  url: '/adminPage',
  icon: 'admin'
},
];

// ==============================|| SLICE - MENU ||============================== //

export const fetchMenu = createAsyncThunk('', async () => {
  // main routes will already contain common + privilaged routes
  const roleBasedRoutes = getMainRoutes();
  let pathsArray = roleBasedRoutes.map((route: any) => route.path);
  let filteredMenuItems = allMenuItems.filter((item: any) => pathsArray.includes(item.url))
  const menuItems = {
    dashboard: {
      id: 'group-dashboard',
      title: 'dashboard',
      type: 'group',
      icon: 'dashboard',
      children: filteredMenuItems
    }
  }
  return menuItems;
});

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    hasError(state, action) {
      state.error = action.payload;
    }
  },

  extraReducers(builder) {
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      state.menu = action.payload.dashboard;
    });
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, activeID } = menu.actions;
