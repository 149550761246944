import { createSlice } from '@reduxjs/toolkit';
type DateRangeSelectorProps = {
  dateRange: {
    startDate: string,
    endDate: string,
    key: string,
    periodicity: string
  }
}

const initialState: DateRangeSelectorProps = {
  dateRange: {
    startDate: new Date(`01/01/${new Date().getFullYear()}`).toLocaleDateString(),
    endDate: new Date().toLocaleDateString(),
    key: 'selection',
    periodicity: 'ytd'
  }
};
const dateRangeAdmin = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },

  }
});
export default dateRangeAdmin.reducer;
export const { setDateRange } = dateRangeAdmin.actions;
