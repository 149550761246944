
import MainLayout from '../layout/MainLayout'
import AuthGuard from 'utils/route-guard/AuthGuard';
import { ping } from 'ldrs'
import { useEffect } from 'react';
import { setUserSelectedRegion, setUserSelectedSubRegion } from 'store/reducers/userRegionSubRegion';
import { useDispatch } from 'store';
import { Box } from '@mui/material';
// BR Routes
export function getDefaultRoute() {
  return [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <DefaultLandingPage /> },
        { path: '/default', element: <DefaultLandingPage /> }
      ]
    }
  ];
}

const DefaultLandingPage = () => {
  const dispatch = useDispatch();
  ping.register();
  let userInfo: any = {};
  try {
    const user = localStorage.getItem('user');
    if (user) userInfo = JSON.parse(user);
  } catch (error) {
    console.error('Error parsing user info:', error);
  }
  useEffect(() => {
    if (userInfo?.myregion?.includes('BR')) {
      dispatch(setUserSelectedRegion('LAO'));
      dispatch(setUserSelectedSubRegion('Brazil'));
    }
    else if (userInfo?.myregion?.includes('UK')) {
      dispatch(setUserSelectedRegion('EMEA'));
      dispatch(setUserSelectedSubRegion('UK'));
    }
  }, [userInfo?.myregion])

  return (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', }}>
    <l-ping
      size="45"
      speed="0.7"
      color="black"
    ></l-ping>
  </Box>)
}

