import { useEffect, useState } from "react"
import { getFilters } from "services/scenario-planner";

export const useGetFilters = (subRegion: string, level: string, source: string) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    getFilters(subRegion, level, source)
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error)
        setLoading(false);
      });
  }, [subRegion, level, source]);

  return { data, error, loading };
}