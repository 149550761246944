import { createSlice } from '@reduxjs/toolkit';

const initialState: SIMULATOR = {
  wasSimulationRun: false,
  simulator: [],
  weekSequence: [],
  simulationResults: <SimResults>{},
  pidDetails: {},
  clearAllScenarios: false
};

const pesimulator = createSlice({
  name: 'pesimulator',
  initialState,
  reducers: {
    addMore(state, action) {
      state.simulator = action.payload;
    },
    setWeekSequence(state, action) {
      state.weekSequence = action.payload;
    },
    setSimulationResults(state, action) {
      state.simulationResults = action.payload;
    },
    setPidDetails(state, action) {
      state.pidDetails = action.payload;
    },
    setClearAllScenarios(state, action) {
      state.clearAllScenarios = action.payload;
    },
    setWasSimulationRun(state, action) {
      state.wasSimulationRun = action.payload;
    },
  }
});
export default pesimulator.reducer;
export const { addMore, setWeekSequence, setSimulationResults, setPidDetails, setClearAllScenarios, setWasSimulationRun } = pesimulator.actions;
