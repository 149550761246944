export function getCustomLegends(
  yAxisData: any,
  fromSimulator: boolean,
  simulation: any,
  scenarioColors: string[],
  activeIndex0: boolean,
  activeIndex1: boolean,
  activeIndex2: boolean,
  type: string
) {
  const legendItems: string[] = [];
  if (fromSimulator) {
    if (yAxisData.length === 4) {
      // Case for 4 series items (3 scenarios)
      if (activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - ${type}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      } else if (!activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      } else if (!activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      } else if (activeIndex0 && !activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - ${type}`
        );
      } else if (!activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      } else if (activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - ${type}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      } else if (activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - ${type}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      }
    } else if (yAxisData.length === 3) {
      // Case for 5 series items (2 scenarios)
      if (activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
            </svg> ${simulation.scenarios[0].sn} - ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      } else if (!activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      } else if (activeIndex0 && !activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
           <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
          </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <rect width="12" height="12" fill="${scenarioColors[0]}" />
          </svg> ${simulation.scenarios[0].sn} - ${type}`
        );
      }
    } else if (yAxisData.length === 2) {
      // Case for 3 series items (1 scenario)
      legendItems.push(
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
          </svg> ORG ${type?.split(' ')[1]}`,

        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <rect width="12" height="12" fill="${scenarioColors[0]}" />
          </svg> ${simulation.scenarios[0].sn} - ${type}`
      );
    }
  } else {
    if (yAxisData.length === 4) {
      // Case for 7 series items (3 scenarios)
      if (activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#3db002" />
            </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      } else if (!activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
           </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                   <rect width="12" height="12" fill="#292FF2" />
                 </svg> ${simulation.scenarios[1].sn} - ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                   <rect width="12" height="12" fill="#3db002" />
                 </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      } else if (!activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
           </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                   <rect width="12" height="12" fill="#3db002" />
                 </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      } else if (activeIndex0 && !activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
           </svg> ORG ${type?.split(' ')[1]}`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> Forecasted ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - ${type}`
        );
      } else if (!activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <rect width="12" height="12" fill="#292FF2" />
        </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      } else if (activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
        </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <rect width="12" height="12" fill="#df8617" />
        </svg> ${simulation.scenarios[0].sn} - ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
        <rect width="12" height="12" fill="#3db002" />
        </svg> ${simulation.scenarios[2].sn} - ${type}`
        );
      } else if (activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      }
    } else if (yAxisData.length === 3) {
      // Case for 5 series items (2 scenarios)
      if (activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - ${type}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      } else if (!activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - ${type}`
        );
      } else if (activeIndex0 && !activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
          </svg> ORG ${type?.split(' ')[1]}`,

          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <rect width="12" height="12" fill="#df8617" />
          </svg> ${simulation.scenarios[0].sn} - ${type}`
        );
      }
    } else if (yAxisData.length === 2) {
      // Case for 3 series items (1 scenario)
      legendItems.push(
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
           <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
        </svg> ORG ${type?.split(' ')[1]}`,

        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <rect width="12" height="12" fill="#df8617" />
        </svg> ${simulation.scenarios[0].sn} - SIM ${type}`
      );
    }
  }
  return legendItems;
}
