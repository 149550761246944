import { postApi } from './common';

export const saveSession = async (payload) => {
    return await postApi('usage-tracker/sessions', payload);
}

export const querySessionsMetrics = async (payload) => {
    return await postApi('usage-tracker/metrics-query/sessions', payload);
}

export const queryPagesMetrics = async (payload) => {
    return await postApi('usage-tracker/metrics-query/pages', payload);
}

export const queryUsersMetrics = async (payload) => {
    return await postApi('usage-tracker/metrics-query/users', payload);
}

export async function invokeSessionApi() {
    let trackedEvents = window.dataLayer ?? [];
    if (trackedEvents.length === 0) return;

    let session = localStorage.getItem('session');
    let sessionId = JSON.parse(session)?.id;
    let user = localStorage.getItem('user') ?? '{}';
    let email = JSON.parse(user)?.email;
    let sessionStart = trackedEvents.find((t) => t.e === 'SESSION_STARTED');
    let sessionEnded = trackedEvents.find((t) => t.e === 'SESSION_ENDED');

    let s = {
        sessionId: sessionId,
        email: email,
        startedAt: sessionStart?.at ?? null,
        endedAt: sessionEnded?.at ?? null,
        events: []
    }

    let eventsToSave = getEventsToSave(trackedEvents, sessionEnded);
    s.events = eventsToSave;
    if (!sessionId) {
        let saveResult = await saveSession(s);
        localStorage.setItem('session', JSON.stringify({ id: saveResult.sessionId }));
    } else {
        saveSession(s);
    }

    // required to prevent duplicate entries in database
    eventsToSave.forEach((e) => {
        let index = window.dataLayer.findIndex((i) => i.id === e.id);
        window.dataLayer.splice(index, 1);
    });
}

function getEventsToSave(trackedEvents, sessionEnded) {
    let eventsToSave = [];

    if (sessionEnded) {
        trackedEvents.forEach((e) => {
            if (e.e === 'pageview') {
                eventsToSave.push({
                    id: e.id,
                    e: e.e,
                    f: e.f,
                    vAt: e.vAt,
                    eAt: sessionEnded.at
                });
            }
        });
    } else {
        trackedEvents.forEach((e1) => {
            /* Skip exit events. Page entrance/visit event in the outer loop will be used to
            find a matching exit event using event id and eAt. See next statement after this if condition.
            */
            if (e1.eAt) return;

            let exit = trackedEvents.find((e2) => e2.id === e1.id && e2.eAt);
            if (exit) {
                eventsToSave.push({
                    id: e1.id,
                    e: e1.e,
                    f: e1.f,
                    vAt: e1.vAt,
                    eAt: exit.eAt
                });
            }
        });
    }
    return eventsToSave;
}
