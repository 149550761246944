import { getApi } from './common';
export const getElasticityLevels = async (subRegion) => {
  return getApi(`scenario-planner/elasticity-levels?subRegion=${subRegion}`)
    .then((data) => data)
    .catch((error) => error);
};

export const getFilters = async (subRegion, level, source) => {
  return getApi(`scenario-planner/filters?subRegion=${subRegion}&level=${level}&source=${source}`)
    .then((data) => data)
    .catch((error) => error);
};