import { useEffect, useState } from "react"
import { getElasticityLevels } from "services/scenario-planner";

export const useGetElasticityLevels = (country: string) => {
  const [data, setData] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    getElasticityLevels(country)
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      });
  }, []);

  return { data, error, loading };
}