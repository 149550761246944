/* eslint-disable */
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Paper, Button, Grid, Typography } from '@mui/material';
import { downLoadSVG, exportPNG } from '../../../components/dashboard/utils/utils';
import { ApexOptions } from 'apexcharts';
import { shortHandNotaion } from 'utils/shortNotationFormatter';
import { getCustomLegends } from './helper';

interface ScenarioResult {
  per: number;
  perAsString: string;
  y: number;
  r: number;
  fv: number;
  sv: number;
  r_fv: number;
}

interface Scenario {
  id: string;
  sn: string;
  screenOrder: number;
  results: ScenarioResult[];
}

export interface Simulation {
  id: string;
  name: string;
  pid: string;
  periodicity: string;
  level: string;
  cat: string;
  brd: string;
  subBrd: string;
  rgn: string;
  scha: string;
  cbp: number;
  cnp: number;
  pe: number;
  rsq: number;
  scenarios: Scenario[];
  created_by: string;
  created_on: string;
}

interface SimulationResultsChartProps {
  simulation: Simulation;
  tableRef?: any;
  fromSimulator: boolean;
}

export default function SimulationResultsRevenueChart({ simulation, tableRef, fromSimulator }: SimulationResultsChartProps) {
  const [chartHeight, setChartHeight] = useState<number>(365);
  const [chartData, setChartData] = useState<Simulation>(simulation);
  const [activeIndex0, toggleActiveIndex0] = useState(true);
  const [activeIndex1, toggleActiveIndex1] = useState(true);
  const [activeIndex2, toggleActiveIndex2] = useState(true);
  const [customLegends, setCustomLegends] = useState<string[]>([]);
  const [maxRevenueAxisValue, setMaxRevenueAxisValue] = useState(0);
  const scenarios = JSON.parse(localStorage.getItem('scenarioCards') as string);
  let scenarioColors = scenarios.map((item: any) => item.color);

  setTimeout(() => {
    if (tableRef && tableRef.current) {
      setChartHeight(chartData.scenarios.length > 0 ? 365 : tableRef.current.clientHeight - 14);
    } else {
      setChartHeight(380);
    }
  }, 100);
  useEffect(() => {
    let filterCharData = simulation;
    let scenarios = filterCharData.scenarios.filter((item, index) => {
      if (index === 0 && activeIndex0) {
        return true;
      }
      if (index === 1 && activeIndex1) {
        return true;
      }
      if (index === 2 && activeIndex2) {
        return true;
      }
      return false;
    });
    filterCharData = { ...filterCharData, scenarios: scenarios };
    setChartData(filterCharData);
    let itemsRArray: any = [];
    let itemsR_FvArray: any = [];
    filterCharData.scenarios.map((item: any) => {
      item?.results?.map((item: any) => {
        itemsRArray.push(item.r)
      })
    });
    filterCharData.scenarios.map((item: any) => {
      item?.results?.map((item: any) => {
        itemsR_FvArray.push(item.r_fv)
      })
    })
    let AllArrays: any = [...itemsRArray, ...itemsR_FvArray]
    setMaxRevenueAxisValue(Math.max(...AllArrays))
  }, [simulation, activeIndex0, activeIndex1, activeIndex2]);

  let options: ApexOptions = {
    chart: {
      id: 'Simulation-Results-Revenue-Chart',
      type: 'line',
      height: chartHeight,
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: true,
        zoomedArea: {
          fill: {
            color: 'red',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 2
          }
        }
      },
      toolbar: {
        show: true,
        offsetX: -30,
        offsetY: 10,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [
            {
              icon: downLoadSVG,
              index: 5,
              title: 'Download PNG',
              class: 'custom-download-icon',
              click: function (chart: any, options: any, e: any) {
                exportPNG(options.globals.chartID);
              }
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: getColors(),
    series: prepSeries(chartData),
    markers: {
      size: 3,
      colors: '#000000',
      hover: {
        size: undefined,
        sizeOffset: 2
      }
    },
    stroke: {
      width: [2, 2, 2, 2],
      curve: 'straight',
      dashArray: [4, 0, 0, 0]
    },
    plotOptions: {
      bar: {
        columnWidth: '70%'
      }
    },
    xaxis: {
      type: 'category',
      categories: chartData.scenarios.length >= 1 ? chartData.scenarios[0].results.map((x: any) => x.perAsString + '-' + x.y) : ''
    },
    yaxis: [
      {
        seriesName: 'Forecasted Revenue',
        title: {
          text: 'Revenue'
        },
        min: 0,
        max: maxRevenueAxisValue,
        labels: {
          formatter: function (val: any) {
            return shortHandNotaion(val);
          }
        }
      },
      {
        show: false,
        opposite: true,
        seriesName: 'SIM Revenue',
        title: {
          text: 'SIM Revenue'
        },
        min: 0,
        max: maxRevenueAxisValue,
        labels: {
          formatter: function (val: any) {
            return shortHandNotaion(val);
          }
        }
      }
    ],
    tooltip: {
      shared: true,
      y: {
        formatter: function (val: any) {
          return val?.toLocaleString();
        }
      }
    },
    legend: {
      markers: {
        height: 0,
        width: 0
      },
      customLegendItems: customLegends,
      onItemClick: {
        toggleDataSeries: false
      },
      position: 'bottom',
      horizontalAlign: 'center',
      offsetY: 15,
      itemMargin: {
        vertical: 20
      }
    },
    grid: {
      padding: {
        bottom: -25,
        top: 18,
        right: 10
      }
    }
  };

  useEffect(() => {
    getCustomLegendItems();
    options = { ...options, legend: { ...options.legend, customLegendItems: customLegends } };
  }, [chartData]);

  function getCustomLegendItems() {
    const yAxisData = prepYAxis(simulation);
    let items: string[] = [];
    items = getCustomLegends(yAxisData, fromSimulator, simulation, scenarioColors, activeIndex0, activeIndex1, activeIndex2, 'SIM Revenue');
    setCustomLegends(items);
  }

  function prepSeries(chartDatas: Simulation) {
    let s: any[] = [];
    if (chartData.scenarios.length >= 1) {
      s.push({
        name: 'ORG Revenue',
        type: 'line',
        data: chartDatas.scenarios[0].results.map((r) => (r.r_fv ? r.r_fv : 0))
      });
    }
    let volumes: any[] = [],
      revenues: any[] = [];
    chartData.scenarios.forEach((scenario, index) => {
      revenues.push({
        name: scenario.sn + ' - SIM Revenue',
        type: 'column',
        data: scenario.results.map((d) => d.r),
        yAxisIndex: 1
      });
    });
    s.push(...volumes, ...revenues);
    return s;
  }

  function prepYAxis(chartData: Simulation) {
    let y: any[] = [];
    let revenues: any[] = [];
    if (chartData.scenarios.length >= 1) {
      y.push({
        seriesName: 'Forecasted Revenue',
        title: {
          text: 'Revenue'
        },
        labels: {
          formatter: function (val: any) {
            return shortHandNotaion(val);
          }
        }
      });
      chartData.scenarios.forEach((scenario) => {
        revenues.push({
          seriesName: 'Forecasted Revenue',
          show: false
        });
      });
    }
    y.push(...revenues);
    return y;
  }
  function getColors() {
    const result = prepYAxis(chartData);
    let colorSet: any = [];
    if (fromSimulator) {
      switch (result.length) {
        case 4:
          colorSet.push('#000', scenarioColors[0], scenarioColors[1], scenarioColors[2]);
          return colorSet;
        case 3:
          if (activeIndex0 && activeIndex1) {
            colorSet.push('#000', scenarioColors[0], scenarioColors[1]);
            return colorSet;
          } else if (activeIndex0 && activeIndex2) {
            colorSet.push('#000', scenarioColors[0], scenarioColors[2]);
            return colorSet;
          } else {
            colorSet.push('#000', scenarioColors[1], scenarioColors[2]);
            return colorSet;
          }
        case 2:
          if (activeIndex0) {
            colorSet.push('#000', scenarioColors[0]);
            return colorSet;
          } else if (activeIndex1) {
            colorSet.push('#000', scenarioColors[1]);
            return colorSet;
          } else if (activeIndex2 && !activeIndex0 && !activeIndex1) {
            return ['#000', scenarioColors[2]];
          } else {
            colorSet.push('#000', scenarioColors[0]);
            return colorSet;
          }
        default:
          return colorSet.push('#000');
      }
    } else {
      switch (result.length) {
        case 4:
          return ['#000', '#df8617', '#292FF2', '#3db002'];
        case 3:
          if (activeIndex0 && activeIndex1) {
            return ['#000', '#df8617', '#292FF2'];
          } else if (activeIndex0 && activeIndex2) {
            return ['#000', '#df8617', '#3db002'];
          } else {
            return ['#000', '#292FF2', '#3db002'];
          }
        case 2:
          if (activeIndex0) {
            return ['#000', '#df8617'];
          } else if (activeIndex1) {
            return ['#000', '#292FF2'];
          } else if (activeIndex2 && !activeIndex0 && !activeIndex1) {
            return ['#000', '#3db002'];
          } else {
            return ['#000', '#df8617'];
          }
        default:
          return ['#000'];
      }
    }
  }

  return (
    <Paper>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 0.001
        }}
      >
        <Grid item sx={{ marginLeft: 3 }}>
          <Typography variant="h4">Revenue Trends</Typography>
        </Grid>
        <Grid item sx={{ marginRight: 4 }}>
          {simulation.scenarios.length >= 1 && (
            <Button
              onClick={() => {
                if (activeIndex1 || activeIndex2) {
                  toggleActiveIndex0(!activeIndex0);
                }
              }}
              variant="contained"
              size="small"
              sx={{
                ':hover': { bgcolor: activeIndex0 ? (fromSimulator ? scenarioColors[0] : 'rgb(242 152 41)') : 'rgb(255 231 201)' },
                marginTop: '5px',
                marginLeft: '10px',
                backgroundColor: activeIndex0 ? (fromSimulator ? scenarioColors[0] : 'rgb(242 152 41)') : 'rgb(255 231 201)'
              }}
            >
              {simulation.scenarios[0].sn}
            </Button>
          )}
          {simulation.scenarios.length >= 2 && (
            <Button
              onClick={() => {
                if (activeIndex0 || activeIndex2) {
                  toggleActiveIndex1(!activeIndex1);
                }
              }}
              variant="contained"
              size="small"
              sx={{
                ':hover': { bgcolor: activeIndex1 ? (fromSimulator ? scenarioColors[1] : 'rgb(41 47 242)') : 'rgb(177 179 225)' },
                marginTop: '5px',
                marginLeft: '10px',
                backgroundColor: activeIndex1 ? (fromSimulator ? scenarioColors[1] : 'rgb(41 47 242)') : 'rgb(177 179 225)'
              }}
            >
              {simulation.scenarios[1].sn}
            </Button>
          )}
          {simulation.scenarios.length >= 3 && (
            <Button
              onClick={() => {
                if (activeIndex1 || activeIndex0) {
                  toggleActiveIndex2(!activeIndex2);
                }
              }}
              variant="contained"
              size="small"
              sx={{
                ':hover': { bgcolor: activeIndex2 ? (fromSimulator ? scenarioColors[2] : 'green') : 'rgb(190 237 166)' },
                marginTop: '5px',
                marginLeft: '10px',
                backgroundColor: activeIndex2 ? (fromSimulator ? scenarioColors[2] : 'green') : 'rgb(190 237 166)'
              }}
            >
              {simulation.scenarios[2].sn}
            </Button>
          )}
        </Grid>
      </Grid>

      <Chart options={options} series={options.series} height={options?.chart?.height} />
    </Paper>
  );
}
