import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'store';
import { Theme } from '@mui/material/styles';
import { useMediaQuery, MenuItem, Stack, TextField, Typography } from '@mui/material';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import Avatar from 'components/@extended/Avatar';
import { MenuOrientation } from 'types/config';
import avatar1 from 'assets/images/users/avatar-1.png';
import { getRegions } from 'store/reducers/dashboard';
import { setUserSelectedRegion, setUserSelectedSubRegion } from 'store/reducers/userRegionSubRegion';

const HeaderContent = () => {
  const dispatch = useDispatch();
  const { menuOrientation } = useConfig();
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { userSelectedRegion, userSelectedSubRegion } = useSelector((state) => state.userRegionSubRegion);
  const { regionsResponse } = useSelector((state) => state.dashboard);

  const [selectedRegion, setSelectedRegion] = useState<string>(userSelectedRegion || "");
  const [selectedSubRegion, setSelectedSubRegion] = useState<string>(userSelectedSubRegion || "");
  const [showGreetings, toggleGreetings] = useState(true);

  let userInfo = { name: '', myrole: [], given_name: '', myregion: [] as string[] };
  try {
    const user = localStorage.getItem('user');
    if (user) userInfo = JSON.parse(user);
  } catch (error) {
    console.error('Error parsing user info:', error);
  }

  useEffect(() => {
    setTimeout(() => toggleGreetings(false), 10000);
    if (localStorage.getItem('authToken')) {
      dispatch(getRegions());
    }
  }, [dispatch]);

  useEffect(() => {
    setSelectedRegion(userSelectedRegion);
    setSelectedSubRegion(userSelectedSubRegion);
  }, [userSelectedRegion, userSelectedSubRegion]);

  let filteredRegions = [];
  if (userInfo.myregion.includes('GLOBAL')) {
    filteredRegions = regionsResponse;
  } else {
    filteredRegions = regionsResponse.filter((region: any) => userInfo.myregion.includes(region.rgn));
  }

  useEffect(() => {
    if (filteredRegions.length > 0 && !selectedRegion) {
      const defaultRegion = filteredRegions[0].rgn;
      setSelectedRegion(defaultRegion);
      dispatch(setUserSelectedRegion(defaultRegion));
    }
  }, [filteredRegions, selectedRegion, dispatch]);

  const handleRegionChange = (e: any) => {
    const newRegion = e.target.value;
    setSelectedRegion(newRegion);
    dispatch(setUserSelectedRegion(newRegion));

    const newSubRegions = filteredRegions.filter((region: any) => region.rgn === newRegion);
    if (newSubRegions.length === 1) {
      const subRegion = newSubRegions[0].srgn;
      setSelectedSubRegion(subRegion);
      dispatch(setUserSelectedSubRegion(subRegion));
    } else {
      setSelectedSubRegion('');
    }
  };

  const handleSubRegionChange = (e: any) => {
    setSelectedSubRegion(e.target.value);
    dispatch(setUserSelectedSubRegion(e.target.value));
  };

  if (!localStorage.getItem('authToken')) return null;

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {showGreetings && (
        <Typography variant="h4" sx={{ ml: 'auto' }}>
          Ola, {userInfo.given_name}!
        </Typography>
      )}
      <Stack direction="row" spacing={2} alignItems="center" sx={{ flexShrink: 0, ml: 0.75, marginLeft: 'auto', order: 2, p: 0.5 }}>
        <TextField
          id="region-select"
          size="medium"
          label="Region"
          select
          value={selectedRegion}
          onChange={handleRegionChange}
          sx={{ minWidth: '120px', '& .MuiInputBase-input': { py: 0.75, fontSize: '0.675rem' } }}
        >
          {filteredRegions.map((region: any) => (
            <MenuItem key={region.rgn} value={region.rgn} sx={{ fontSize: '0.675rem' }}>
              {region.rgn}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="subregion-select"
          size="medium"
          label="SubRegion"
          select
          value={selectedSubRegion}
          onChange={handleSubRegionChange}
          sx={{ minWidth: '120px', '& .MuiInputBase-input': { py: 0.75, fontSize: '0.675rem' } }}
        >
          {filteredRegions
            .filter((region: any) => region.rgn === selectedRegion)
            .map((subRegion: any) => (
              <MenuItem key={subRegion.srgn} value={subRegion.srgn} sx={{ fontSize: '0.675rem' }}>
                {subRegion.srgn}
              </MenuItem>
            ))}
        </TextField>

        <Stack direction="row" spacing={2} alignItems="center" sx={{ flexShrink: 0, ml: 0.75, p: 0.5 }}>
          <Avatar alt="profile user" src={avatar1} size="xs" />
          <Stack direction="column">
            <Typography variant="h6" sx={{ fontSize: '0.775rem' }}>
              {userInfo.name}
            </Typography>
            <Stack direction="row">
              {userInfo?.myrole?.map((role, index) => (
                <Typography key={role} variant="h6" sx={{ fontSize: '0.775rem', marginRight: '5px' }}>
                  {role}
                  {index < userInfo.myrole.length - 1 ? ',' : ''}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default HeaderContent;
