import { CloseOutlined } from '@ant-design/icons';
import { dotStream, hourglass } from 'ldrs';
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import generatePDF from 'react-to-pdf';

import { getTooltips } from '../../../services/elasticity';
import ResultCard from '../SimulationResults/ResultCard';
import SimulationResultsChart, { Simulation } from '../SimulationResults/SimulationResultsChart';
import SimulationInputsTable from './SimulationInputsTable';
import SimulationResultsRevenueChart from '../SimulationResults/SimulationResultsRevenueChart';
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';
import { DownCircleFilled, PrinterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import ProfitParabolaChart from '../PidDetailsCard/ProfitParabola';

hourglass.register();
dotStream.register();

interface ResultsDialogProps {
  simulationToRender: Simulation | null;
  openDialog: boolean;
  closeHandler: () => void;
}

const RenderLoader = () => {
  return <l-dot-stream size="25" speed="2" color="black"></l-dot-stream>;
};

const RenderSimulationDetailCard = ({
  label,
  value,
  loader,
  toolTipInfo
}: {
  label: string;
  value: number | undefined;
  loader: boolean;
  toolTipInfo: string;
}) => {
  return (
    <div
      style={{
        paddingLeft: '8px',
        display: 'flex',
        background: '#dbdbdb',
        fontWeight: '600',
        fontSize: '0.90rem',
        color: '#111',
        padding: '8px',
        borderRadius: '4px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      <Typography>{label} | </Typography> <span style={{ marginLeft: '10px' }}> {loader ? <RenderLoader /> : value}</span>
      <Tooltip placement="top" title={toolTipInfo}>
        <InfoCircleOutlined style={{ color: '#938e8e', fontSize: '0.95rem', padding: '0 2px 2px', marginLeft: '7px' }} />
      </Tooltip>
    </div>
  );
};

export default function ResultsDialog({ simulationToRender, openDialog, closeHandler }: ResultsDialogProps) {
  const colorCode = ['#f29829', '#0000ff', '#3db002'];
  const [loader, toggleLoader] = useState(false);
  const [showExpanded, toggleExpanded] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState<any>({});
  const targetRef = useRef(null);

  useEffect(() => {
    getTooltips().then((result: any) => {
      setTooltipInfo(result.data);
    });
  }, []);

  const exportPdf = () => {
    toggleExpanded(true);
    toggleLoader(true);
    setTimeout(() => {
      generatePDF(targetRef, { filename: `${simulationToRender?.name}-${moment().format('DD-MM-YYYY')}.pdf` });
    }, 1000);

    setTimeout(() => {
      toggleLoader(false);
    }, 1000);
  };

  return (
    <Dialog open={openDialog} onClose={closeHandler} scroll="paper" fullWidth={true} maxWidth={false}>
      <div ref={targetRef}>
        <DialogTitle sx={{ display: 'grid', backgroundColor: '#f4f4f4', marginBottom: 1 }}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography fontSize={'16px'}>
              {!simulationToRender ? null : (
                <span style={{ fontWeight: '1000' }}>
                  {simulationToRender?.pid}-{simulationToRender?.rgn}-{simulationToRender?.scha}
                </span>
              )}
            </Typography>
            <Box>
              <IconButton
                onClick={() => exportPdf()}
                sx={{
                  ':hover': {
                    bgcolor: '#262828', // theme.palette.primary.main
                    color: 'white',
                    borderColor: 'black'
                  },
                  height: '20px',
                  width: '20px',
                  color: 'black',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  border: '1px solid black',
                  marginRight: '20px'
                }}
              >
                {loader ? <RenderLoader /> : <PrinterOutlined style={{ fontSize: '12px' }} />}
              </IconButton>

              <IconButton
                onClick={closeHandler}
                sx={{
                  height: '20px',
                  width: '20px',
                  color: 'black',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  border: '1px solid black'
                }}
              >
                <CloseOutlined style={{ fontSize: '12px' }} />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography fontSize={12}>
              Simulation Name:
              {!simulationToRender ? (
                <RenderLoader />
              ) : (
                <span style={{ marginLeft: '4px', fontWeight: '1000' }}>{simulationToRender?.name}</span>
              )}
            </Typography>
            <Typography fontSize={12}>
              Created on:
              {!simulationToRender ? (
                <RenderLoader />
              ) : (
                <span style={{ fontWeight: '1000', marginLeft: '4px' }}>{simulationToRender?.created_on.split(',')[0]}</span>
              )}
            </Typography>
            <Typography fontSize={12}>
              Created by:
              {!simulationToRender ? (
                <RenderLoader />
              ) : (
                <span style={{ fontWeight: '1000', marginLeft: '4px' }}>{simulationToRender?.created_by}</span>
              )}
            </Typography>
          </Stack>
        </DialogTitle>
        <Grid container columnSpacing={1} paddingX={3} paddingBottom={1}>
          <Grid item xs={3}>
            <RenderSimulationDetailCard
              label="Current Sell Out Price"
              value={simulationToRender?.cbp}
              loader={!simulationToRender ? true : false}
              toolTipInfo={tooltipInfo?.cbp}
            />
          </Grid>
          <Grid item xs={3}>
            <RenderSimulationDetailCard
              label="Current Net Price"
              value={simulationToRender?.cnp}
              loader={!simulationToRender ? true : false}
              toolTipInfo={tooltipInfo?.cnp}
            />
          </Grid>
          <Grid item xs={3}>
            <RenderSimulationDetailCard
              label="Price Elasticity"
              value={simulationToRender?.pe}
              loader={!simulationToRender ? true : false}
              toolTipInfo={tooltipInfo?.pe}
            />
          </Grid>
          <Grid item xs={3}>
            <RenderSimulationDetailCard
              label="R-Squared"
              value={simulationToRender?.rsq}
              loader={!simulationToRender ? true : false}
              toolTipInfo={tooltipInfo?.srsq}
            />
          </Grid>
        </Grid>
        <DialogContent>
          {!simulationToRender ? (
            <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <l-hourglass size="45" speed="1.5" color="black"></l-hourglass>
            </Box>
          ) : (
            <Box sx={{ gridrow: 2, gridColumn: 1, display: 'grid' }}>
              <Box sx={{ gridrow: 1, gridColumn: 1, marginTop: 1, minWidth: 'calc(100% - 360px)' }}>
                <Stack direction="row" className="simulationResults" sx={{ width: '100%', justifyContent: 'center', paddingBottom: 1 }}>
                  <Box sx={{ gridrow: 1, gridColumn: 1, marginTop: 1, minWidth: 'calc(70% - 360px)' }} className="simulationResults">
                    {simulationToRender && <SimulationResultsChart fromSimulator={false} simulation={simulationToRender} />}
                  </Box>
                  <Box
                    sx={{ gridrow: 1, gridColumn: 2, marginTop: 1, marginLeft: 1, minWidth: 'calc(70% - 360px)' }}
                    className="simulationResults"
                  >
                    {simulationToRender && <SimulationResultsRevenueChart fromSimulator={false} simulation={simulationToRender} />}
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={simulationToRender?.scenarios.length < 2 ? 1 : `${1 / simulationToRender?.scenarios.length}%`}
                  className="simulationResults"
                  sx={{ width: '100%', justifyContent: 'center', paddingBottom: 1 }}
                >
                  {simulationToRender?.scenarios.map((sim: any, index: number) => {
                    return (
                      <MainCard sx={{ minWidth: `${Math.floor(100 / simulationToRender?.scenarios.length)}%` }}>
                        <Stack direction="column">
                          <Stack direction="row" sx={{ mb: 1, mt: -0.5 }}>
                            <Dot
                              size={10}
                              sx={{
                                display: 'inline-block',
                                width: '30px',
                                height: '7px',
                                borderRadius: '10px',
                                backgroundColor: colorCode[index],
                                marginTop: '8px'
                              }}
                            />
                            <Typography variant="h1" sx={{ fontSize: '16px', marginLeft: 1.5 }}>
                              {sim.sn}
                            </Typography>
                          </Stack>
                          <ScenarioInput
                            data={sim?.inputs}
                            color={colorCode[index]}
                            cnp={simulationToRender?.cnp}
                            showExpanded={showExpanded}
                          ></ScenarioInput>
                          <ScenarioList
                            showExpanded={showExpanded}
                            isFromViewSimulation={true}
                            key={index}
                            period={simulationToRender?.periodicity}
                            name={sim.sn}
                            scenarios={sim.results}
                            color={colorCode[index]}
                          ></ScenarioList>
                        </Stack>
                      </MainCard>
                    );
                  })}
                </Stack>
              </Box>
              <Box sx={{ gridrow: 2, gridColumn: 1, display: 'grid' }}>
                <ProfitParabolaChart product={{ ...simulationToRender }} />
              </Box>
            </Box>
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
}

const ScenarioInput = ({ data, color, cnp, showExpanded }: { data: any; color: string; cnp: number; showExpanded: boolean }) => {
  const [expanded, setExpanded] = useState(showExpanded);
  useEffect(() => {
    setExpanded(showExpanded);
  }, [showExpanded]);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<DownCircleFilled />}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
          }
        }}
      >
        Scenario Inputs
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '5px' }}>
        <SimulationInputsTable data={data} color={color} cnp={cnp} />
      </AccordionDetails>
    </Accordion>
  );
};
const ScenarioList = ({
  period,
  name,
  scenarios,
  color,
  isFromViewSimulation,
  showExpanded,
  key
}: {
  period: string;
  name: string;
  scenarios: any;
  color: string;
  isFromViewSimulation?: boolean;
  showExpanded: boolean;
  key: number;
}) => {
  const [expanded, setExpanded] = useState(showExpanded);
  useEffect(() => {
    setExpanded(showExpanded);
  }, [showExpanded]);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<DownCircleFilled />}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
          }
        }}
      >
        Simulated Results
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '5px' }}>
        <ResultCard isFromViewSimulation={true} key={key} period={period} name={name} scenarios={scenarios} color={color} />
      </AccordionDetails>
    </Accordion>
  );
};
