import { useMemo } from "react";
import { baseConfig, baseOrder } from "../config";

export const useConfig = (elasticityLevel: string): any => {
  return useMemo(() => {
    let config = { ...baseConfig };
    let order = [...baseOrder];

    switch (elasticityLevel) {
      case "Brand":
        delete config.subBrd;
        delete config.ppg;
        order = order.filter((key) => key !== "subBrd" && key !== "ppg");
        break;
      case "Sub Brand":
        delete config.ppg;
        order = order.filter((key) => key !== "ppg");
        break;
    }

    return { config, order };
  }, [elasticityLevel]);
};