import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/Loadable';

const LoginCallback = Loadable(lazy(() => import('pages/Login/callback')));

export function getCommonRoutes() {
  return [
    {
      path: '/login',
      element: <MainLayout />,
      children: [{ path: 'callback', element: <LoginCallback /> }]
    }
  ];
}

