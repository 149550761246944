import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Stack, Typography, Grid, Card } from '@mui/material';
import { useTable, useSortBy, HeaderGroup } from 'react-table';
import { HeaderSort } from 'components/third-party/ReactTable';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
// project import
import Dot from 'components/@extended/Dot';

function ReactTable({
  columns,
  scenarioName,
  data,
  color,
  isFromViewSimulation
}: {
  columns: any;
  scenarioName: string;
  data: any;
  color: string;
  isFromViewSimulation: boolean;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  return (
    <Grid item xs={4} sx={{ maxWidth: '100% !important' }}>
      <Box>
        {!isFromViewSimulation && (
          <Stack direction="row" alignItems="center" padding={0.5}>
            <Dot size={10} sx={{ display: 'inline-block', width: '30px', height: '7px', borderRadius: '10px', backgroundColor: color }} />
            <Typography variant="h1" sx={{ fontSize: '16px', marginLeft: 1.5 }}>
              {scenarioName}
            </Typography>
          </Stack>
        )}

        <Table stickyHeader {...getTableProps()} sx={{ fontSize: '0.58rem', border: 1, borderColor: '#d6d6d6', width: '100%' }}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((hg: HeaderGroup<{}>, index: number) => {
                  return (
                    <TableCell
                      {...hg.getHeaderProps([{ className: hg.className }])}
                      sx={{ fontSize: '0.60rem', padding: '5px', position: 'sticky !important', border: 1, borderColor: '#d6d6d6' }}
                      style={{ padding: '5px' }}
                    >
                      <Stack direction="row">
                        <HeaderSort column={hg} sort tooltip={hg.tooltip} />
                      </Stack>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          fontSize: '0.6rem',
                          border: 1,
                          borderColor: '#d6d6d6',
                          padding: '5px',
                          textAlign: cell.column.id === 'perAsString' || cell.column.id === 'y' ? 'left !important' : 'right !important'
                        }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Grid>
  );
}

const ResultCard = ({
  period,
  name,
  scenarios,
  color,
  isFromViewSimulation
}: {
  period: string;
  name: string;
  scenarios: any;
  color: string;
  isFromViewSimulation?: boolean;
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [updatedScenarios, setScenarios] = useState(scenarios);
  useEffect(() => {
    const tempScenarios = scenarios.map((item: any) => {
      item = { ...item, perAsString: item.perAsString + '-' + item.y };
      return item;
    });
    setScenarios(tempScenarios);
    if (!isFromViewSimulation) divRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);
  const periodColumn =
    period === 'monthly'
      ? {
        Header: 'Month',
        accessor: 'perAsString'
      }
      : {
        Header: 'Qtr',
        accessor: 'perAsString',
        tooltip: 'Quarter'
      };

  const columns = useMemo(
    () => [
      periodColumn,
      {
        Header: 'Org Revenue',
        accessor: 'r_fv'
      },
      {
        Header: 'Sim Revenue',
        accessor: 'r'
      },
      {
        Header: 'Org Vol',
        accessor: 'fv',
        tooltip: 'Projected volume assuming no price changes'
      },
      {
        Header: 'Sim Vol',
        accessor: 'sv',
        tooltip: 'Projected volume with user-defined price changes'
      }
    ],
    []
  );

  return (
    <Card sx={{ py: 2, px: 1 }} className="simulationResultsCard">
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <div ref={divRef}></div>
        <ReactTable
          columns={columns}
          scenarioName={name}
          data={updatedScenarios}
          color={color}
          isFromViewSimulation={isFromViewSimulation || false}
        />
      </DndProvider>
    </Card>
  );
};

export default ResultCard;
