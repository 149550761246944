
export const baseConfig: any = {
    mfr: { type: "single", label: "Manufacturer" },
    cust: { type: "single", label: "Customer" },
    cat: { type: "single", label: "Category" },
    brd: { type: "multi", label: "Brand" },
    subBrd: { type: "multi", label: "Sub Brand" },
    ppg: { type: "multi", label: "PPG" },
};

export const baseOrder = ["mfr", "cust", "cat", "brd", "subBrd", "ppg"];