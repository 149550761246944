import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getBRRoutes } from './BR-Routes';
import { getUKRoutes } from './UK-Routes';
import { getCommonRoutes } from './CommonRoutes';
import { getPrivilegedRoutes } from './PrivilegedRoutes';
import { getDefaultRoute } from './Default-route';

export function getMainRoutes() {
  return [...getCommonRoutes(), ...getPrivilegedRoutes()];
}

const MainRoutes = () => {
  const { userSelectedRegion, userSelectedSubRegion } = useSelector((state: any) => state.userRegionSubRegion);
  const selectedRoutes = useMemo(() => {
    if (!userSelectedSubRegion) return getDefaultRoute();
    if (userSelectedSubRegion === 'Brazil') return getBRRoutes();
    if (userSelectedSubRegion === 'United Kingdom') return getUKRoutes();
    return [];
  }, [userSelectedRegion, userSelectedSubRegion]);

  const routes = useMemo(() => [...getMainRoutes(), ...selectedRoutes], [selectedRoutes]);
  let allRoutes = ([...routes, { path: '*', element: <Navigate to="/" replace /> }]);
  return allRoutes;
};

export default MainRoutes;
