import { useRef, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTracking } from "react-tracking";

export function useAppEventTracker({ pageName }: any) {
    let eventId = useRef<string>(uuidv4());
    const { trackEvent, getTrackingData } = useTracking<any>({ f: pageName, id: eventId.current },
        { dispatchOnMount: (context: any) => ({ e: 'pageview', vAt: new Date() }) });
    useEffect(() => {
        return () => {
            trackEvent({ ...getTrackingData(), eAt: new Date() });
        }
    }, []);
}