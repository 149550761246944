import { useEffect, useState, useCallback, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Slider, Typography, Stack, CircularProgress, Tooltip, TextField, InputAdornment, IconButton } from '@mui/material';
import { getProfitParabola, getProfitParabolaFile } from 'services/price-elasticity-simulator';
import { shortHandNotaion } from 'utils/shortNotationFormatter';
import { InfoCircleOutlined, PercentageOutlined, DownloadOutlined } from '@ant-design/icons';
import { dispatch } from 'store';
import { setProfitParabola } from 'store/reducers/pesimulator';
import SnackbarAlert from 'components/common/SnackbarAlert';

const ProfitParabolaChart = (props) => {
  const [hasParabolaData, setHasParabolaData] = useState(true);
  const [fixedCostShare, setFixedCostShare] = useState(30);
  const [debouncedFixedCostShare, setDebouncedFixedCostShare] = useState(30);
  const [xValues, setXValues] = useState([]);
  const [volume, setVolume] = useState([]);
  const [gpPercentage, setGpPercentage] = useState([]);
  const [parabolaLoading, setParabolaLoading] = useState(true);
  const debounceTimeout = useRef(null);
  const [isParblaFileDownloading, setIsParabolaFileDownloading] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);
  const API_ERROR_MESSAGE = 'Something went wrong! Please try again after sometime...';

  const debounce = useCallback((value) => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      setDebouncedFixedCostShare(value);
    }, 700); // debounce 700ms
  }, []);

  async function callProfitParabolaApi(fixedCostShare) {
    setParabolaLoading(true);
    setHasParabolaData(true);
    let payload = {
      elsc: props.product?.pe,
      fcs: fixedCostShare === '' ? 0 : parseFloat(fixedCostShare),
      pid: props.product?.pid,
      level: props.product?.level,
      region: props.product?.rgn,
      channel: props.product?.scha,
      pim: {
        min: -15,
        max: 15
      },
      countType: 'pack'
    };

    dispatch(
      setProfitParabola({
        fcs: fixedCostShare === '' ? 0 : parseFloat(fixedCostShare),
        pim_min: -15,
        pim_max: 15
      })
    );

    getProfitParabola(payload)
      .then((data) => {
        setParabolaLoading(false);
        setXValues(data?.fdp?.map((item) => item?.pim));
        setVolume(data?.fdp?.map((item) => item?.vf));
        setGpPercentage(data?.fdp?.map((item) => item?.gppf));
      })
      .catch((err) => {
        setParabolaLoading(false);
      });
  }
  useEffect(() => {
    for (let i = 0; i < volume?.length; i++) {
      if (volume[i] === 0 && gpPercentage[i] === null) {
        setHasParabolaData(false);
      }
    }
  }, [volume, gpPercentage]);

  useEffect(() => {
    if (fixedCostShare !== '') callProfitParabolaApi(debouncedFixedCostShare);
  }, [debouncedFixedCostShare]);

  const series = [
    {
      name: 'Volume Forecast',
      data: volume,
      color: '#0047AB'
    },
    {
      name: 'GP% Forecast',
      data: gpPercentage,
      color: '#FF5733'
    }
  ];

  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true,
        type: 'y',
        autoScaleYaxis: true,
        zoomedArea: {
          fill: {
            color: 'red',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 2
          }
        }
      },
      toolbar: {
        show: true
      }
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: {
      categories: xValues,
      tickAmount: 5,
      labels: {
        formatter: (val) => `${val}%`
      },
      title: {
        text: 'Price Increase Model',
        position: 'bottom',
        offsetY: -12
      }
    },
    yaxis: [
      {
        title: {
          text: 'Forecast Values'
        },
        labels: {
          formatter: (value) => shortHandNotaion(value)
        }
      },
      {
        opposite: true,
        title: {
          text: 'GP % Forecast'
        },
        labels: {
          formatter: (value) => `${value}`
        },
        seriesName: 'GP% Forecast'
      }
    ],
    colors: ['#0047AB', '#FF5733'],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center'
    },
    grid: {
      padding: {
        bottom: -8
      }
    }
  };

  const handleSliderChange = (event, newValue) => {
    setFixedCostShare(newValue);
    debounce(newValue);
  };
  const handleFixedCostShareChange = (value) => {
    const regex = /^-?\d*\.?\d{0,2}$/; // allowing only digits with 2 decimal places
    if (regex.test(value) && value <= 100) {
      setFixedCostShare(value);
      debounce(value);
    }
  };

  async function handleDownloadExcelFile() {
    let payload = {
      elsc: props.product?.pe,
      fcs: fixedCostShare === '' ? 0 : parseFloat(fixedCostShare),
      pid: props.product?.pid,
      level: props.product?.level,
      region: props.product?.rgn,
      channel: props.product?.scha,
      pim: {
        min: -15,
        max: 15
      },
      countType: 'pack'
    };
    try {
      setIsParabolaFileDownloading(true);
      const value = await getProfitParabolaFile(payload);
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Profit_Parabola.xlsx`);
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      URL.revokeObjectURL(url);
      setIsParabolaFileDownloading(false);
      setApiErrorMsg(null);
    } catch (reason) {
      setIsParabolaFileDownloading(false);
      setApiErrorMsg(API_ERROR_MESSAGE);
    }
  }

  return (
    <>
      <SnackbarAlert clearMessage={() => setApiErrorMsg(null)} message={apiErrorMsg} sevierity={'error'} />
      <Box sx={{ paddingTop: 7 }}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ ml: 5 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h4" fontSize={'18px'}>
              Profit Parabola
            </Typography>
            <Tooltip placement="top" title="The report is based on actual data from the past year and will not update during simulations.">
              <InfoCircleOutlined style={{ color: '#262626', fontSize: '0.85rem', padding: '0.5rem' }} />
            </Tooltip>
          </Box>
          {hasParabolaData && (
            <Box>
              <Stack direction={'row'} sx={{ mr: '12%', mb: '4px' }}>
                <Box sx={{ mt: 0.3, minWidth: '120px' }}>
                  <Typography variant="h6">Fixed Cost Share</Typography>
                </Box>
                <Slider
                  value={fixedCostShare}
                  min={0}
                  max={100}
                  step={1}
                  disabled={parabolaLoading}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  sx={{
                    width: '120px',
                    marginLeft: '2px',
                    marginRight: '15px',
                    color: '#000',
                    '& .MuiSlider-track': {
                      border: 'none',
                      height: 4
                    },
                    '& .MuiSlider-rail': {
                      color: '#000'
                    },
                    '& .MuiSlider-thumb': {
                      height: 18,
                      width: 18,
                      backgroundColor: '#fff',
                      border: '2px solid currentColor',
                      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'inherit'
                      },
                      '&::before': {
                        display: 'none'
                      }
                    }
                  }}
                  valueLabelDisplay="on"
                />
                <Box sx={{ marginRight: '5px' }}>
                  <TextField
                    type="text"
                    value={fixedCostShare}
                    onChange={(e) => handleFixedCostShareChange(e.target.value)}
                    size="small"
                    sx={{
                      width: '60px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black'
                        },
                        '&:hover fieldset': {
                          borderColor: 'black'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black'
                        },
                        '&:hover .MuiOutlinedInput-input': {
                          color: 'black'
                        },
                        '&.Mui-focused .MuiOutlinedInput-input': {
                          color: 'black'
                        }
                      },
                      '& .MuiOutlinedInput-root.Mui-disabled': {
                        '&:hover fieldset': {
                          borderColor: 'lightGray'
                        }
                      }
                    }}
                    InputProps={{
                      style: { fontSize: 12 },
                      endAdornment: (
                        <InputAdornment position="end" sx={{ margin: -1 }}>
                          <PercentageOutlined style={{ fontSize: '12px' }} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Tooltip placement="top" title="Download CSV">
                  <Box>
                    <IconButton
                      disabled={isParblaFileDownloading}
                      onClick={handleDownloadExcelFile}
                      size="small"
                      sx={{
                        border: `1px solid #111`,
                        color: '#111',
                        '&:hover': { backgroundColor: '#141414', color: '#fff' },
                        bgcolor: 'grey.100',
                        '&.MuiIconButton-root': {
                          height: '28px'
                        }
                      }}
                    >
                      {isParblaFileDownloading ? <CircularProgress size={15} color="success" /> : <DownloadOutlined />}
                    </IconButton>
                  </Box>
                </Tooltip>
              </Stack>
            </Box>
          )}
        </Stack>
        {!parabolaLoading ? (
          hasParabolaData ? (
            <ReactApexChart options={options} series={series} type="line" height={300} />
          ) : (
            <>
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    width: '75%',
                    top: '35%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    pointerEvents: 'none',
                    textAlign: 'center'
                  }}
                >
                  <Box
                    sx={{
                      height: '40px',
                      width: '100%',
                      backgroundColor: '#9999a1',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      Profit Parabola data is not available for the selected Product!
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ opacity: 0.6, pointerEvents: 'none' }}>
                  <ReactApexChart options={options} series={series} type="line" height={300} />
                </Box>
              </Box>
            </>
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10%'
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfitParabolaChart;
