import { Alert, AlertColor, Snackbar, Typography } from "@mui/material"
import { useEffect, useState } from "react"

interface SelectorProps {
  message: string | null;
  sevierity: AlertColor;
  clearMessage: () => void;
}

const SnackbarAlert = ({ clearMessage, message, sevierity }: SelectorProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
    clearMessage()
  }
  useEffect(() => {
    !!message ? setOpen(true) : setOpen(false)
  }, [message])

  return (<>
    {open && <Snackbar
      open={open}
      autoHideDuration={7000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ margin: 8 }}
    >
      <Alert
        onClose={handleClose}
        severity={sevierity}
        variant="filled"
        sx={{ width: '100%', paddingTop: .25, paddingBottom: .25 }}
      >
        <Typography fontSize={'15px'}>{message}</Typography>
      </Alert>
    </Snackbar>}
  </>
  )
}
export default SnackbarAlert;