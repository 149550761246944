import { createSlice } from '@reduxjs/toolkit';
type Region_SubRegion = { region: string, subRegion: string }
function getInitial() {
  let userInfo: any = [];
  const user = localStorage.getItem('user');
  if (user) {
    userInfo = JSON.parse(user);
    let region_SubRegion: Region_SubRegion = { region: '', subRegion: '' };
    if (userInfo?.myregion?.includes('UK')
      && !userInfo?.myregion?.includes('GLOBAL')
      && !userInfo?.myregion?.includes('BR')
    ) {
      region_SubRegion = { region: 'EMEA', subRegion: 'United Kingdom' }
    }
    else {
      region_SubRegion = { region: 'LAO', subRegion: 'Brazil' }
    }
    return region_SubRegion;
  }
}
let initialRegionSubRegion = getInitial();
const initialState: any = {

  userSelectedRegion: initialRegionSubRegion?.region,
  userSelectedSubRegion: initialRegionSubRegion?.subRegion
};

const userRegionSubRegion = createSlice({
  name: 'userRegionSubRegion',
  initialState,
  reducers: {
    setUserSelectedRegion(state, action) {
      state.userSelectedRegion = action.payload;
    },
    setUserSelectedSubRegion(state, action) {
      state.userSelectedSubRegion = action.payload;
    }
  }
});

export default userRegionSubRegion.reducer;
export const { setUserSelectedRegion, setUserSelectedSubRegion } = userRegionSubRegion.actions;
