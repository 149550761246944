import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'store';

// material-ui
import { Theme } from '@mui/material/styles';
import { useMediaQuery, MenuItem, Stack, TextField, Typography } from '@mui/material';

// project import
// import Search from './Search';
// import MegaMenuSection from './MegaMenuSection';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import Avatar from 'components/@extended/Avatar';

// types
import { MenuOrientation } from 'types/config';
// assets
import avatar1 from 'assets/images/users/avatar-1.png';

import { getRegions } from '../../../../store/reducers/dashboard';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const dispatch = useDispatch();
  const { menuOrientation } = useConfig();
  const [selectedRegion, setSelectedRegion] = useState('LAO');
  const [selectedSubRegion, setSubRegion] = useState('Brazil');
  const { regions, subRegions } = useSelector((state) => state.dashboard);
  const user = localStorage.getItem('user');
  const [showGreetings, toggleGreetings] = useState(true);

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  let userInfo = { name: '', myrole: [], given_name: '' };
  if (user) {
    userInfo = JSON.parse(user as string);
  }

  useEffect(() => {
    setTimeout(() => toggleGreetings(false), 10000);
    if (localStorage.getItem('authToken')) dispatch(getRegions());
    // dispatch(getSubRegions());
  }, []);

  if (!localStorage.getItem('authToken')) {
    return <></>
  }

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {/* {!downLG && <Search />} */}
      {showGreetings && <Typography variant="h4" sx={{ ml: 'auto' }}>{`  Ola,  ${userInfo.given_name}!`}</Typography>}
      {/* {downLG && <Box sx={{ width: '100%', ml: 1 }} />} */}
      <Stack direction="row" spacing={2} alignItems="center" sx={{ flexShrink: 0, ml: 0.75, marginLeft: 'auto', order: 2, p: 0.5 }}>
        {
          <TextField
            id="standard-select-currency"
            size="medium"
            label="Region"
            select
            value={regions.length > 0 ? selectedRegion : ''}
            onChange={(e) => setSelectedRegion(e.target.value)}
            sx={{ flexShrink: 0, ml: 0.75, order: 2, minWidth: '120px', '& .MuiInputBase-input': { py: 0.75, fontSize: '0.675rem' } }}
          >
            {regions.map((option: any) => (
              <MenuItem sx={{ fontSize: '0.675rem' }} key={option.value} value={option?.value || null}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        }
        {
          <TextField
            id="standard-select-currency"
            size="medium"
            label="SubRegion"
            select
            value={subRegions.length > 0 ? selectedSubRegion : ''}
            onChange={(e) => setSubRegion(e.target.value)}
            sx={{
              flexShrink: 0,
              ml: 0.75,
              marginLeft: 'auto',
              order: 2,
              minWidth: '120px',
              '& .MuiInputBase-input': { py: 0.75, fontSize: '0.675rem' }
            }}
          >
            {subRegions.map((option: any) => (
              <MenuItem sx={{ fontSize: '0.675rem' }} key={option.value} value={option?.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        }
        {
          <Stack direction="row" spacing={2} alignItems="center" sx={{ flexShrink: 0, ml: 0.75, marginLeft: 'auto', order: 2, p: 0.5 }}>
            <Avatar alt="profile user" src={avatar1} size="xs" />
            <Stack direction="column">
              <p className="usernme">{userInfo.name} </p>
              <Stack direction="row">
                {userInfo?.myrole?.map((item: string, index: number) => {
                  return (<Typography key={item} variant="h6" sx={{ fontSize: '0.775rem', marginRight: '5px' }}>{item + `${index < userInfo?.myrole?.length - 1 ? ',' : ''}`}  </Typography>)
                })}
              </Stack>
            </Stack>
          </Stack>
        }
      </Stack>
    </>
  );
};

export default HeaderContent;
