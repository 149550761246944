import { getApi, postApi } from './common';

export const getFilters = async (levelValue, sourceName) => {
  return getApi(`price-elasticity-simulator/filters?level=${levelValue}&source=${sourceName}`)
    .then((data) => data)
    .catch((error) => error);
};

export const getPidDetails = async (pid, level, channel, region, category, brd, subBrd) => {
  return postApi('price-elasticity-simulator/pid-history-query', { pid: pid, level: level, channel: channel, region: region, category: category, brand: brd, subBrand: subBrd })
};

export const getSimulationResults = async (simId) => {
  return postApi('price-elasticity-simulator/simulation-details-query', { id: simId });
}

export const getSimulationMetrics = async (req) => {
  return postApi(`price-elasticity-simulator/metrics-query`, req)
    .then((data) => data)
    .catch((error) => error);
}
export const getSavedSimulationFilters = async (level) => {
  return getApi(`price-elasticity-simulator/saved-sim-filters?level=${level}`)
}

export const getSavedSimulations = async (level, region, channel, category, brand, subBrand) => {
  return postApi('price-elasticity-simulator/saved-simulations', { level, region, channel, category, brand, subBrand });
}
export const saveSimulation = async (payload) => {
  return postApi('price-elasticity-simulator/simulations', payload);
}

export const getProductLevels = async () => {
  return getApi(`price-elasticity-simulator/product-levels`)
    .then((data) => data)
    .catch((error) => error);
};

export const getProfitParabola = async (payload) => {
  return postApi('price-elasticity-simulator/sim-profit-parabola', payload);
};

export function getProfitParabolaFile(request) {
  return postApi('price-elasticity-simulator/profit-parabola-download', request);
}
