import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Slider, Typography, Stack, CircularProgress, Tooltip } from '@mui/material';
import { getProfitParabola } from 'services/price-elasticity-simulator';
import { shortHandNotaion } from 'utils/shortNotationFormatter';
import { InfoCircleOutlined } from '@ant-design/icons';

const ProfitParabolaChart = (props) => {
  const [fixedCostShare, setFixedCostShare] = useState(30);
  const [xValues, setXValues] = useState([])
  const [volume, setVolume] = useState([])
  const [gpPercentage, setGpPercentage] = useState([]);
  const [parabolaLoading, setParabolaLoading] = useState(true);

  async function callProfitParabolaApi(fixedCostShare) {
    setParabolaLoading(true);
    let payload = {
      elsc: props.product?.pe,
      fcs: fixedCostShare,
      pid: props.product?.pid,
      level: props.product?.level,
      region: props.product?.rgn,
      channel: props.product?.scha,
      pim: {
        min: -15,
        max: 15
      },
      countType: "pack"
    }

    getProfitParabola(payload)
      .then((data) => {
        setParabolaLoading(false);
        setXValues(data?.fdp?.map(item => item?.pim))
        setVolume(data?.fdp?.map(item => item?.vf));
        setGpPercentage(data?.fdp?.map(item => item?.gppf));
      }).catch((err) => {
        setParabolaLoading(false);
      })
  };

  useEffect(() => {
    callProfitParabolaApi(fixedCostShare)
  }, [fixedCostShare])

  const series = [
    {
      name: 'Volume Forecast',
      data: volume,
      color: '#0047AB',
    },
    {
      name: 'GP% Forecast',
      data: gpPercentage,
      color: '#FF5733',
    }
  ];

  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true,
        type: 'y',
        autoScaleYaxis: true,
        zoomedArea: {
          fill: {
            color: 'red',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 2
          }
        }
      },
      toolbar: {
        show: true,
      }
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    xaxis: {
      categories: xValues,
      tickAmount: 5,
      labels: {
        formatter: (val) => `${val}%`,
      },
      title: {
        text: 'Price Increase Model',
        position: 'bottom',
        offsetY: -12
      }
    },
    yaxis: [
      {
        title: {
          text: 'Forecast Values',
        },
        labels: {
          formatter: (value) => shortHandNotaion(value),
        },
      },
      {
        opposite: true,
        title: {
          text: 'GP % Forecast',
        },
        labels: {
          formatter: (value) => `${value}`,
        },
        seriesName: 'GP% Forecast',
      },
    ],
    colors: ['#0047AB', '#FF5733'],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
    grid: {
      padding: {
        bottom: -8,
      }
    },
  };

  const handleSliderChange = (event, newValue) => {
    setFixedCostShare(newValue);
  };

  return (<Box sx={{ paddingTop: 7 }}>
    <Stack direction={"row"} justifyContent={"space-between"} sx={{ ml: 5 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h4" fontSize={'18px'}>Profit Parabola</Typography>
        <Tooltip placement="top" title="The report is based on actual data from the past year and will not update during simulations.">
          <InfoCircleOutlined style={{ color: '#938e8e', fontSize: '0.85rem', padding: '0.5rem' }} />
        </Tooltip>
      </Box>
      <Box>
        <Stack direction={"row"}>
          <Box sx={{ mt: 1.25, minWidth: '130px' }}>
            <Typography variant="h6">Fixed Cost Share %:</Typography>
          </Box>
          <Slider
            value={fixedCostShare}
            min={1}
            max={100}
            step={1}
            disabled={parabolaLoading}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            sx={{
              width: '150px',
              margin: '6px',
              marginRight: '12%',
              color: '#000',
              '& .MuiSlider-track': {
                border: 'none',
                height: 4
              },
              '& .MuiSlider-thumb': {
                height: 18,
                width: 18,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                  boxShadow: 'inherit',
                },
                '&::before': {
                  display: 'none',
                },
              },
            }}
            valueLabelDisplay="on"
          />
        </Stack>
      </Box>
    </Stack>
    {!parabolaLoading ? <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={300}
    /> :
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: '10%'
      }}>
        <CircularProgress color="inherit" />
      </Box>
    }
  </Box >
  );
};

export default ProfitParabolaChart;