import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AdminPage = Loadable(lazy(() => import('pages/admin')));

// Privileged Routes
export function getPrivilegedRoutes() {
  const user = localStorage.getItem('user');
  if (!user) return [];

  const userAccessDetails = JSON.parse(user || "");
  const adminRoles = ['KCA', 'Dev_UI', 'Dev_Admin'];
  if (Array.isArray(userAccessDetails.myrole)
    && userAccessDetails.myrole.some((role: any) => adminRoles.includes(role))) {
    return [{
      path: '/adminPage',
      element: <AuthGuard><MainLayout /></AuthGuard>,
      children: [{ path: '/adminPage', element: <AdminPage /> }]
    }];
  } else {
    return [];
  }
}

