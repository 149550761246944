import axios from 'utils/axios';
import { dispatch } from 'store';
import { getFormattedDate } from 'components/date/utils/utils';

// third-party
import { createSlice } from '@reduxjs/toolkit';
// project import
interface cat {
  value: string;
  label: string;
}

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const token = localStorage.getItem('authTtoken');
const sellinFillerList: any = [
  {
    value: 'Sell In',
    label: 'Sell In'
  },
  {
    value: 'Sell Out',
    label: 'Sell Out'
  }
];

const initialState: any = {
  sourceFilter: 'Sell In',
  sourceList: sellinFillerList,
  marketShareData: [],
  categoryList: [],
  category: '',
  regions: [],
  subRegions: [],
  selectedRegion: '',
  selectedSubRegion: '',
  actualNetsalesGraphFilters: {
    category: [],
    channel: [],
    region: []
  },
  fromDate: `${new Date().getFullYear()}-01-01`,
  toDate: getFormattedDate(new Date(), 'yyyy-mm-dd'),
  loginState: false,
  authError: '',
  apiToken: '',
  oktaAccessToken: '',
  oktaIdToken: ''
};
//Auth state

// ==============================|| INVOICE - SLICE ||============================== //

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // handler customer form popup
    selectSourceFIlter(state, action) {
      state.sourceFilter = action.payload.sourceFilter;
    },
    setCategory(state, action) {
      state.category = action.payload.category;
    },
    // get all invoice list
    getMarketShareData(state, action) {
      state.marketShareData = action.payload;
    },
    getCategoriesList(state, action) {
      state.categoryList = action.payload;
    },
    getRegions(state, action) {
      state.regions = action.payload;
    },
    setRegion(state, action) {
      state.selectedRegion = action.payload.region;
    },
    getSubRegions(state, action) {
      state.subRegions = action.payload;
    },
    setSubRegion(state, action) {
      state.selectedSubRegion = action.payload.subRegion;
    },
    setFromDate(state, action) {
      state.fromDate = action.payload;
    },
    setToDate(state, action) {
      state.toDate = action.payload;
    },
    setLoginState(state: any, action) {
      state.loginState = action.payload;
    },
    setAuthError(state: any, action: any) {
      state.authError = action.payload;
    },
    setAPIToken(state: any, action: any) {
      state.apiToken = action.payload;
    },
    setOktaAccessToken(state: any, action: any) {
      state.oktaAccessToken = action.payload;
    },
    setOktaIdToken(state: any, action: any) {
      state.oktaIdToken = action.payload;
    }
  }
});

export default dashboard.reducer;

export const {
  selectSourceFIlter,
  setCategory,
  setFromDate,
  setToDate,
  setOktaAccessToken,
  setOktaIdToken,
  setAPIToken,
  setAuthError,
  setLoginState
} = dashboard.actions;
export function getMarketShareData() {
  return async () => {
    try {
      const response = await axios.get('core-insights/getMarketShare');
      dispatch(dashboard.actions.getMarketShareData(response.data.dashboard));
    } catch (error) {
      dispatch(dashboard.actions.getMarketShareData([]));
    }
  };
}
export function getCategories() {
  return async () => {
    try {
      const response = await axios.get('/core-insights/filters?source=Sell In');
      console.log('response,', response);
      let tempArray: cat[] = [];
      dispatch(dashboard.actions.getCategoriesList(tempArray));
      // dispatch(dashboard.actions.setCategory({category:response.data.data[0]}));
    } catch (error) {
      dispatch(dashboard.actions.getCategoriesList([]));
    }
  };
}
export function getRegions() {
  return async () => {
    try {
      const token = localStorage.getItem('authToken');
      const user = localStorage.getItem('user');
      let userInfo = { myregion: [] };
      if (user) {
        userInfo = JSON.parse(user as string);
      }
      let temparry = userInfo.myregion; //['BR', 'LAO', 'GLOBAL', 'testmyregion'];
      const response = await axios.get(BASE_URL + `/core-insights/getRegions?reg=` + JSON.stringify(temparry), {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      });

      if (response && response.data.error) {
        localStorage.setItem('authToken', '');
        dispatch(dashboard.actions.setLoginState(false));
      }
      let tempArray: cat[] = [];
      let subRegion: cat[] = [];
      if (response && response.data && response.data.data) {
        response.data.data.dp.forEach((item: any) => {
          tempArray.push({
            value: item.rgn,
            label: item.rgn
          });
          subRegion.push({
            value: item.srgn,
            label: item.srgn
          });
        });
        dispatch(dashboard.actions.getRegions(tempArray));
        dispatch(dashboard.actions.setRegion({ region: tempArray[0].value }));
        dispatch(dashboard.actions.getSubRegions(subRegion));
        dispatch(dashboard.actions.setSubRegion({ subRegion: subRegion[0].value }));
      }
    } catch (error) {
      dispatch(dashboard.actions.getRegions([]));
    }
  };
}
export function getSubRegions() {
  return async () => {
    try {
      const response = await axios.get('core-insights/getSubRegions');
      let tempArray: cat[] = [];
      response.data.data.forEach((item: string) => {
        tempArray.push({
          value: item,
          label: item
        });
      });
      dispatch(dashboard.actions.getSubRegions(tempArray));
      dispatch(dashboard.actions.setSubRegion({ region: response.data.data[0] }));
    } catch (error) {
      dispatch(dashboard.actions.getSubRegions([]));
    }
  };
}
