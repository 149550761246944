import { FunnelPlotOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Box, IconButton, Stack } from '@mui/material';
import MainCard from "components/MainCard";
import { useAppEventTracker } from "hooks/useAppEventTracker";
import { hourglass } from 'ldrs';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { getSavedSimulations, getSimulationResults } from "services/price-elasticity-simulator";
import CustomButton from "../Common/CustomButton";
import { Simulation } from "../SimulationResults/SimulationResultsChart";
import FilterPopper from "./Filters";
import ResultsDialog from "./ResultsDialog";
import SavedSimulationsTable, { SavedSimulationProps } from "./Table";

interface SelectedFilters {
  level: string;
  regions: string[];
  channels: string[];
  categories: string[];
  brands: string[];
  subBrands: string[];
}

const SavedSimulationsView = () => {
  const navigate = useNavigate();
  hourglass.register();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    level: 'sub_brand',
    regions: [],
    channels: [],
    categories: [],
    brands: [],
    subBrands: [],
  });
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const anchorEl = useRef<any>(null);
  const [savedSimulations, setSavedSimulations] = useState<SavedSimulationProps>();
  const [tableLoader, setTableLoader] = useState(false);
  const [simulationToRender, setSimulationToRender] = useState<Simulation | null>(null);
  const cache = useRef<Simulation[]>([]);
  const containerRef = useRef(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  useAppEventTracker({ pageName: 'Saved Simulations' });

  const handleTogglePopper = () => {
    setPopperOpen((prevOpen) => !prevOpen);
  };

  const handleClosePopper = () => {
    setPopperOpen(false);
  };
  const handleFiltersChange = (filters: any) => {
    setSelectedFilters(filters);
  };

  async function handleSimulationSelection(simulationId: string) {
    setSimulationToRender(null);
    let s = cache.current.find((value, index, arr) => arr[index].id === simulationId);
    setOpenDialog(true);
    if (s) {
      setSimulationToRender(s);
    }
    else {
      let simulation = await getSimulationResults(simulationId);
      cache.current.push(simulation)
      setSimulationToRender(simulation);
    }
  }

  function handleClose() {
    setOpenDialog(false);
  }

  useEffect(() => {
    setTableLoader(true);
    const { level, regions, channels, categories, brands, subBrands } = selectedFilters;
    getSavedSimulations(level, regions, channels, categories, brands, subBrands)
      .then((res: any) => {
        setSavedSimulations(res);
        setTableLoader(false);
      }).catch((err) => {
        setTableLoader(false);
      })
  }, [selectedFilters])

  return (
    <MainCard ref={containerRef}>
      <Stack height={30} direction='row' justifyContent='space-between' margin={2}>
        <CustomButton
          text="Go Back"
          startIcon={<LeftCircleOutlined style={{ fontSize: '16px' }} />}
          onClick={() => navigate(-1)}
        />
        <Box>
          <IconButton ref={anchorEl} onClick={handleTogglePopper}>
            <FunnelPlotOutlined style={{ color: 'black' }} />
          </IconButton>
          {/* <IconButton aria-label="menu" >
            <UnorderedListOutlined style={{ color: 'black' }} />
          </IconButton> */}
        </Box>
      </Stack>
      <FilterPopper
        anchorEl={anchorEl}
        open={popperOpen}
        handleClose={handleClosePopper}
        onFiltersChange={handleFiltersChange}
      />
      {tableLoader
        ? <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <l-hourglass
            size="45"
            speed="1.5"
            color="black"
          ></l-hourglass>
        </Box>
        : <SavedSimulationsTable simulations={savedSimulations?.simulations || []} handleRowSelection={handleSimulationSelection} />}
      <ResultsDialog simulationToRender={simulationToRender} openDialog={openDialog} closeHandler={handleClose} />
    </MainCard >
  )
}

export default SavedSimulationsView;